import React from "react";
import Vizzly from "@vizzly/dashboard";
import "./App.css";

function App() {
  return (
    <Vizzly.Dashboard
      parentDashboardId="dsh_d819f98dfb3a47fa85dc379259d91130"
      queryEngineEndpoint="https://example.vizzly.co/query-engine"
      identity={async () =>
        Promise.resolve({
          dashboardAccessToken:
            "eyJhbGciOiJFUzI1NiIsImtpZCI6IldhMlZ4TDRwNXhVNDNidWhEMjFmRWcwWEU1Z2M5S2lJMHhOMFRaN252THcifQ.eyJhY2Nlc3NUeXBlIjoiYWRtaW4iLCJvcmdhbmlzYXRpb25JZCI6Im9yZ180MWQ4NjgyZWQ1YTA0NzRjYTcwNTlkMjExZTJhMWE3ZCIsInVzZXJSZWZlcmVuY2UiOiJ1c2VyLXJlZmVyZW5jZS1nZXQtc3RhcnRlZCIsInNjb3BlIjoicmVhZF93cml0ZSIsImV4cGlyZXMiOiIyMDIzLTA2LTA2VDE5OjI3OjQ1LjMzN1oifQ.a_cMOJrmUPruCRFDPkYFrwKaDXhsIpxwCWhRLOg-oieO5K-bi2g1Am14HeHJHW0WV5HYUcr11lSO2_TYMa3Ldw",
        })
      }
    />
  );
}

export default App;

// {
//   "dashboardAccessToken": "eyJhbGciOiJFUzI1NiIsImtpZCI6IldhMlZ4TDRwNXhVNDNidWhEMjFmRWcwWEU1Z2M5S2lJMHhOMFRaN252THcifQ.eyJhY2Nlc3NUeXBlIjoiYWRtaW4iLCJvcmdhbmlzYXRpb25JZCI6Im9yZ180MWQ4NjgyZWQ1YTA0NzRjYTcwNTlkMjExZTJhMWE3ZCIsInVzZXJSZWZlcmVuY2UiOiJ1c2VyLXJlZmVyZW5jZS1nZXQtc3RhcnRlZCIsInNjb3BlIjoicmVhZF93cml0ZSIsImV4cGlyZXMiOiIyMDIzLTA2LTA2VDE5OjI3OjQ1LjMzN1oifQ.a_cMOJrmUPruCRFDPkYFrwKaDXhsIpxwCWhRLOg-oieO5K-bi2g1Am14HeHJHW0WV5HYUcr11lSO2_TYMa3Ldw",
//   "dataAccessToken": "eyJhbGciOiJFUzI1NiIsImtpZCI6IldhMlZ4TDRwNXhVNDNidWhEMjFmRWcwWEU1Z2M5S2lJMHhOMFRaN252THcifQ.eyJkYXRhU2V0SWRzIjoiKiIsInNlY3VyZUZpbHRlcnMiOnt9LCJleHBpcmVzIjoiMjAyMy0wNi0wNlQxOToyNzo0NS4zMzFaIn0.KTACRj4Xj_K_tSH2fG2YvQVmbV-ZNfRD9gbie0NO32Pg5twg876x4PCR4E0NJU3Juz6a0EbPXB6qCZnSnQhsMw"
// }
